import type { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/system'
import React, { FC, useCallback, useMemo, useState } from 'react'

import { LuneTheme } from '../theme'

import Select from './Select'

const EMPTY = '__EMPTY__'

export const MutuallyExclusiveListOption = ({
    children,
}: {
    name: string
    children: React.ReactNode | React.ReactNode[]
}) => <>{children}</>

const MutuallyExclusiveList: FC<{
    title?: string
    defaultName?: string
    discriminator?: string
    children: React.ReactNode[]
    sx?: SxProps
}> = ({ title, defaultName, children, sx, ...rest }) => {
    const { typography, spacing, palette } = LuneTheme
    const [value, setValue] = useState<string>(defaultName ?? EMPTY)

    const childrenArray = React.Children.toArray(children)
    const options: Record<string, React.ReactNode[]> = {}
    for (let i = 0; i < childrenArray.length; i++) {
        const element = childrenArray[i]
        if (!React.isValidElement(element)) {
            throw new Error('Invalid react element')
        }
        const { props } = element
        // eslint-disable-next-line react/prop-types
        if (!props.name) {
            throw new Error(
                `Element must have a non-empty string name prop (child ${i} in ${JSON.stringify(childrenArray)})`,
            )
        }

        // eslint-disable-next-line react/prop-types
        options[props.name] = React.Children.toArray(props.children)
    }

    const selectOption = useCallback(
        (name: string) => {
            setValue(name)
        },
        [value],
    )

    const StyledMutuallyExclusiveList = useMemo(
        () =>
            styled(Table)(
                LuneTheme.unstable_sx({
                    ...typography.body1,
                    border: `solid 1px ${palette.Grey300}`,
                    borderRadius: '8px',
                    borderCollapse: 'separate',
                    borderSpacing: '0px',
                    '& .MuiTableCell-root.MutuallyExclusiveList': {
                        border: 'none',
                        textTransform: 'none',
                    },
                    '& .MuiTableBody-root > tr:not(:first-child) > td.MutuallyExclusiveList': {
                        borderTop: `solid 1px ${palette.Grey300}`,
                    },
                    '& .MuiTableRow-root': {
                        backgroundColor: palette.White,
                    },
                    '& .MuiTableBody-root > tr:first-child > td.MutuallyExclusiveList': {
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px',
                        border: 'none',
                    },
                    '& .MuiTableBody-root > tr:last-child > td.MutuallyExclusiveList': {
                        borderBottomLeftRadius: '8px',
                        borderBottomRightRadius: '8px',
                    },
                }),
            ),
        [],
    )

    return (
        <Box sx={sx} {...rest}>
            <Select
                sx={{
                    ...typography.button,
                    border: `solid 1px ${palette.Grey300} !important`,
                    backgroundColor: palette.White,
                    borderRadius: '40px',
                    height: '40px',
                    width: 'auto',
                    padding: '0px 16px',
                    '& .MuiMenuItem-root': {
                        minHeight: '20px !important',
                        padding: '0px',
                    },
                    '&:hover': {
                        border: `solid 1px ${palette.Grey300}`,
                        backgroundColor: palette.White,
                    },
                }}
                value={value}
                items={[
                    ...(defaultName ? [] : [{ label: title ?? 'One of', value: EMPTY }]),
                    ...Object.keys(options).map((name) => ({
                        label: name,
                        value: name,
                    })),
                ]}
                onChange={(value) => selectOption(value)}
            />
            {value !== EMPTY && (
                <StyledMutuallyExclusiveList
                    sx={{
                        marginTop: spacing(1),
                    }}
                >
                    <TableBody data-testid="mutually-exclusive-list-body">
                        {Array.isArray(options[value]) &&
                            options[value].map((item, i) => (
                                <TableRow key={i}>
                                    <TableCell className="MutuallyExclusiveList">{item}</TableCell>
                                </TableRow>
                            ))}
                        {!Array.isArray(options[value]) && (
                            <TableRow>
                                <TableCell className="MutuallyExclusiveList">
                                    {options[value]}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </StyledMutuallyExclusiveList>
            )}
        </Box>
    )
}

export default MutuallyExclusiveList
