import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import AirOutlinedIcon from '@mui/icons-material/AirOutlined'
import AspectRatioOutlinedIcon from '@mui/icons-material/AspectRatioOutlined'
import Co2OutlinedIcon from '@mui/icons-material/Co2Outlined'
import FactoryOutlinedIcon from '@mui/icons-material/FactoryOutlined'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import ForestOutlinedIcon from '@mui/icons-material/ForestOutlined'
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined'
import NatureOutlinedIcon from '@mui/icons-material/NatureOutlined'
import PetsOutlinedIcon from '@mui/icons-material/PetsOutlined'
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined'
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined'
import type { SxProps } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React, { FC } from 'react'

import Tile from './Tile'

export interface ResultTileProps {
    iconName: string
    text: string
    iconSx?: SxProps
    textSx?: SxProps
}
export interface IconMap {
    [key: string]: React.ReactElement
}

const BulletPoint = (props: { sx?: SxProps }) => {
    const { palette } = useTheme()

    return (
        <div style={{ display: 'flex', justifyContent: 'center', width: '24px' }}>
            <FiberManualRecordIcon
                sx={{ color: palette.Grey900, width: '10px', height: '10px', ...(props.sx || {}) }}
            />
        </div>
    )
}

const ProjectResultsTile: FC<ResultTileProps> = ({ iconName, text, iconSx, textSx }) => {
    const sx = { color: `Grey900`, ...(iconSx || {}) }

    const iconMap: IconMap = {
        aspectRatio: <AspectRatioOutlinedIcon sx={sx} />,
        pets: <PetsOutlinedIcon sx={sx} />,
        volunteerActivism: <VolunteerActivismOutlinedIcon sx={sx} />,
        co2: <Co2OutlinedIcon sx={sx} />,
        forest: <ForestOutlinedIcon sx={sx} />,
        addCircle: <AddCircleOutlinedIcon sx={sx} />,
        inventory: <InventoryOutlinedIcon sx={sx} />,
        factory: <FactoryOutlinedIcon sx={sx} />,
        nature: <NatureOutlinedIcon sx={sx} />,
        air: <AirOutlinedIcon sx={sx} />,
        verified: <VerifiedOutlinedIcon sx={sx} />,
    }

    return (
        // iconMap doesn't actually have values for all keys
        <Tile
            icon={
                (iconMap[iconName] as React.ReactElement | undefined) || <BulletPoint sx={iconSx} />
            }
        >
            <Typography variant={'body2'} sx={{ whiteSpace: 'normal', ...(textSx || {}) }}>
                {text}
            </Typography>
        </Tile>
    )
}

export default ProjectResultsTile
