import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import type { SxProps } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/system'
import React, { FC, useCallback, useMemo, useState } from 'react'

import { LuneTheme } from '../theme'

import Button from './Button'

const ExpandableList: FC<{
    open?: boolean
    title?: string
    children: React.ReactNode[]
    sx?: SxProps
}> = ({ open, title, children, sx, ...rest }) => {
    const { typography, palette } = LuneTheme
    const [isOpen, setOpen] = useState<boolean>(open ?? false)

    const toggle = useCallback(() => {
        setOpen(!isOpen)
    }, [isOpen])

    const StyledExpandableList = useMemo(
        () =>
            styled(Table)(
                LuneTheme.unstable_sx({
                    ...typography.body1,
                    border: `solid 1px ${palette.Grey300}`,
                    borderRadius: '8px',
                    borderCollapse: 'separate',
                    borderSpacing: '0px',
                    '& .MuiTableCell-root.ExpandableList': {
                        border: 'none',
                        borderTop: `solid 1px ${palette.Grey300}`,
                        textTransform: 'none',
                    },
                    '& .MuiTableCell-head.ExpandableList': {
                        padding: '0px',
                        borderTop: 'none',
                    },
                    '.MuiTableRow-root': {
                        backgroundColor: palette.White,
                    },
                    '& .MuiTableHead-root tr:first-child th.ExpandableList': {
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px',
                    },
                    '& .MuiTableBody-root tr:last-child td.ExpandableList': {
                        borderBottomLeftRadius: '8px',
                        borderBottomRightRadius: '8px',
                    },
                }),
            ),
        [],
    )

    return (
        <>
            {!isOpen ? (
                <Button
                    sx={{
                        ...typography.button,
                        border: `solid 1px ${palette.Grey300}`,
                        height: '40px',
                        '&:hover': {
                            border: `solid 1px ${palette.Grey300}`,
                            backgroundColor: 'inherit',
                        },
                    }}
                    variant="outlined"
                    leftIcon={<AddIcon sx={{ width: '24px' }} />}
                    onClick={() => toggle()}
                >
                    {`Show ${title ?? ''}`.trim()}
                </Button>
            ) : (
                <StyledExpandableList sx={sx} {...rest}>
                    <TableHead>
                        <TableRow>
                            <TableCell className="ExpandableList">
                                <Button
                                    sx={{
                                        ...typography.button,
                                        height: '38px',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'left',
                                        border: 'none',
                                        '&:hover': {
                                            backgroundColor: palette.White,
                                        },
                                        '&:active': {
                                            backgroundColor: palette.White,
                                        },
                                    }}
                                    variant="text"
                                    leftIcon={<CloseIcon sx={{ width: '24px' }} />}
                                    onClick={() => toggle()}
                                >
                                    {`Hide ${title ?? ''}`.trim()}
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody data-testid="expandable-list-body">
                        {children.map((child, i) => (
                            <TableRow key={i}>
                                <TableCell className="ExpandableList">{child}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </StyledExpandableList>
            )}
        </>
    )
}

export default ExpandableList
