export { useIsMobile } from './useIsMobile'
export { searchByWordPrefix } from './searchByWordPrefix'
export * from './luneAssetsToDynamicAssets'

/**
 * A helper to get statically imported images' paths.
 *
 * It's needed because this code is used in two contexts and static image imports behave
 * differently in them:
 *
 * * lune-ui-lib standalone when building it with Rollup
 * * lune-ui-lib code included and built as part of the website (a Next.js application)
 * @param image Whatever an import like `import LuneLogo from '../images/lune-logo.png';`
 * produces.
 * @returns An image path.
 */
export function staticImagePath(image: string | { src: string }): string {
    if (typeof image === 'string') {
        return image
    }
    return image.src
}
