import Box from '@mui/material/Box'
import { styled, useTheme } from '@mui/material/styles'
import { QRCodeSVG } from 'qrcode.react'
import { FC, MouseEventHandler } from 'react'

import '@fontsource/space-grotesk'

import CarbonCreditCertificateImg from '../images/CarbonCreditCertificate.png'
import CertificateNoiseBg from '../images/certificate-noise-bg.png'
import LuneLogo from '../images/lune-logo.png'
import RotatingBadge from '../images/rotating-badge.png'
import StaticBadge from '../images/static-badge.png'
import { LuneTheme } from '../theme'
import { staticImagePath } from '../utils'

import Button from './Button'
import GradientSVG from './GradientSVG'

import '@fontsource/space-grotesk/700.css'

export interface CertificateProps {
    id: string
    allocatedAmount: string
    retiredAmount: string
    clientName: string
    createdDate: string
    QRCodeUrl: string
    backgroundGradientHash: number
    updatedDate?: string
    onClick?: MouseEventHandler<HTMLElement>
}

const Certificate: FC<CertificateProps> = ({
    id,
    allocatedAmount,
    retiredAmount,
    clientName,
    createdDate,
    updatedDate,
    QRCodeUrl,
    backgroundGradientHash,
    onClick,
}) => {
    const { palette } = useTheme()

    const BackgroundImage = styled('img')(
        LuneTheme.unstable_sx({
            position: 'absolute',
            left: '0',
            right: '0',
            width: '100%',
        }),
    )
    const Logo = styled('img')(
        LuneTheme.unstable_sx({
            position: 'absolute',
            left: '24px',
            top: '24px',
            height: '24px',
        }),
    )

    const Badge = styled('img')(
        LuneTheme.unstable_sx({
            position: 'absolute',
            left: '0',
            top: '0',
            height: '64px',
            width: '64px',

            '&.static-badge': {
                backgroundBlendMode: 'luminosity',
                mixBlendMode: 'luminosity',
                opacity: '0.8',
            },

            '&.rotating-badge': {
                backgroundBlendMode: 'screen',
                mixBlendMode: 'screen',
                animation: 'rotating 24s linear infinite 0ms',
                opacity: '1',
            },

            '@keyframes rotating': {
                '0%': {
                    transform: 'rotate(0deg)',
                },
                '100%': {
                    transform: 'rotate(360deg)',
                },
            },
        }),
    )

    const CarbonCreditCertificate = styled('img')(
        LuneTheme.unstable_sx({
            position: 'absolute',
            left: '5px',
            top: '5px',
            height: '54px',
            width: '54px',
        }),
    )

    const { amount, retirementTypeLabel } =
        retiredAmount === '0 tCO2' || !retiredAmount
            ? { amount: allocatedAmount, retirementTypeLabel: 'allocated' }
            : { amount: retiredAmount, retirementTypeLabel: 'retired' }
    return (
        <Box
            sx={{
                position: 'relative',
                height: '0',
                width: '100%',
                paddingBottom: '100%',
            }}
        >
            <Box
                sx={{
                    borderRadius: '32px',
                    zIndex: 9,
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    position: 'absolute',
                    left: '0',
                    top: '0',
                    width: '100%',
                    height: '100%',
                    background: palette.White,
                }}
            >
                {onClick && (
                    <Button
                        onClick={onClick}
                        sx={{
                            background: 'transparent !important',
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            bottom: '0',
                            right: '0',
                            width: '100%',
                            height: '100%',
                            zIndex: '9',
                            borderRadius: 0,
                            '&:hover, &:focus': {
                                background: 'transparent !important',
                            },
                        }}
                    />
                )}
                <Box
                    sx={{
                        position: 'absolute',
                        left: '0',
                        right: '0',
                        width: '100%',
                        height: '100%',
                        svg: {
                            position: 'absolute',
                            left: '0',
                            right: '0',
                            width: '100%',
                            height: '100%',
                        },
                    }}
                >
                    <GradientSVG hashNumber={backgroundGradientHash} />
                    <BackgroundImage
                        draggable={false}
                        sx={{
                            backgroundBlendMode: 'difference, normal',
                            mixBlendMode: 'overlay',
                            opacity: 0.5,
                        }}
                        src={staticImagePath(CertificateNoiseBg)}
                    />
                </Box>
                <Logo src={staticImagePath(LuneLogo)} />
                <Box
                    sx={{
                        zIndex: 1,
                        textAlign: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        padding: '24px',
                        fontSize: '26px',
                        fontWeight: 700,
                        lineHeight: '32px',
                        fontFamily: 'Space Grotesk',
                        fontVariantNumeric: 'slashed-zero',
                    }}
                >
                    <span>• {amount} •</span>
                    <span>{retirementTypeLabel} on behalf of</span>
                    <span>{clientName}</span>
                </Box>
                <Box
                    sx={{
                        position: 'absolute',
                        right: '24px',
                        top: '24px',
                        fontSize: '10px',
                        borderRadius: '100%',
                        overflow: 'hidden',
                        height: '64px',
                        width: '64px',
                    }}
                >
                    <Badge src={staticImagePath(StaticBadge)} className={'static-badge'} />
                    <Badge src={staticImagePath(RotatingBadge)} className={'rotating-badge'} />
                    <CarbonCreditCertificate src={staticImagePath(CarbonCreditCertificateImg)} />
                </Box>
                <Box
                    sx={{
                        position: 'absolute',
                        right: '24px',
                        bottom: '24px',
                        fontSize: '10px',
                        lineHeight: '12px',
                        fontWeight: 500,
                        zIndex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        fontFamily: 'Space Grotesk',
                    }}
                >
                    <span>#{String(id)}</span>
                    <span>{createdDate}</span>
                    {updatedDate && (
                        <>
                            <span style={{ marginTop: '8px' }}>Last updated</span>
                            <span>{updatedDate}</span>
                        </>
                    )}
                </Box>
                <Box
                    sx={{
                        position: 'absolute',
                        left: '24px',
                        bottom: '24px',
                    }}
                >
                    <Box>
                        <QRCodeSVG value={QRCodeUrl} bgColor={'transparent'} size={40} />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Certificate
