import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import React, { FC } from 'react'

import '@fontsource/space-grotesk'

import { LuneTheme } from '../theme'
import { useIsMobile } from '../utils'

import Button from './Button'
import Loading from './Loading'
import Modal from './Modal'

const CertificateModal: FC<{
    openModal: boolean
    onClose: () => void
    tilesRetired?: React.ReactNode
    tilesAllocated?: React.ReactNode
    registries: React.ReactNode
    certificate: React.ReactNode
    clientName: string
    loading?: boolean
    disableModal?: boolean
}> = ({
    onClose,
    openModal,
    certificate,
    tilesRetired,
    tilesAllocated,
    clientName,
    registries,
    loading,
    disableModal,
}) => {
    const { palette } = LuneTheme

    const { isMobile } = useIsMobile()

    const mobileSx = {
        borderRadius: '0',
        height: '100%',
        margin: 0,
        maxWidth: '100%',
        maxHeight: '100%',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    }

    const content = (
        <Box
            sx={{
                width: isMobile ? '100%' : '396px',
                padding: isMobile ? '24px 0' : '0',
                boxSizing: 'border-box',
            }}
        >
            {isMobile && (
                <Button
                    iconButton
                    leftIcon={<CloseIcon sx={{ width: '24px' }} />}
                    variant={'text'}
                    onClick={() => onClose()}
                    sx={{ mb: 2 }}
                />
            )}
            {loading ? (
                <Box
                    sx={{
                        height: '500px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Loading sx={{ height: '48px', width: '48px' }} />
                </Box>
            ) : (
                <>
                    <Box
                        sx={{
                            maxWidth: '380px',
                            maxHeight: '380px',
                            margin: 'auto',
                            m: isMobile ? 1 : 'auto',
                            mt: 1,
                        }}
                    >
                        {certificate}
                    </Box>
                    <Box
                        sx={{
                            pl: 3,
                            pr: 3,
                            pb: 3,
                            pt: 2,
                            mb: 1,
                        }}
                    >
                        <Box
                            sx={{
                                '*': {
                                    fontFamily:
                                        'Space Grotesk, Arial, Helvetica, sans-serif !important',
                                },
                            }}
                        >
                            {!tilesRetired && !tilesAllocated ? (
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: '700',
                                    }}
                                >
                                    No credits retired yet
                                </Typography>
                            ) : (
                                <>
                                    {tilesRetired && (
                                        <>
                                            <Typography
                                                sx={{
                                                    fontSize: '14px',
                                                    fontWeight: '700',
                                                    mb: 1,
                                                }}
                                            >
                                                Retired
                                            </Typography>
                                            <Box sx={{ mb: 2 }}>{tilesRetired}</Box>
                                        </>
                                    )}
                                    {tilesAllocated && (
                                        <>
                                            <Typography
                                                sx={{
                                                    fontSize: '14px',
                                                    fontWeight: '700',
                                                    mb: 1,
                                                }}
                                            >
                                                Allocated, pending retirement
                                            </Typography>
                                            <Box sx={{ mb: 2 }}>{tilesAllocated}</Box>
                                        </>
                                    )}
                                    {registries && (
                                        <Box>
                                            <Typography
                                                sx={{
                                                    fontSize: '14px',
                                                    fontWeight: '700',
                                                }}
                                            >
                                                Credits issued by
                                            </Typography>

                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    flexWrap: 'nowrap',
                                                    overflow: 'auto',
                                                    margin: '0 -32px',
                                                    pl: 4,
                                                    marginTop: '12px',
                                                }}
                                            >
                                                {registries}
                                            </Box>
                                        </Box>
                                    )}
                                </>
                            )}
                        </Box>
                        <Box
                            sx={{
                                pt: 2,
                                mt: 2,
                                borderTop: `1px solid ${palette.Grey300}`,
                            }}
                        >
                            <Typography variant={'caption'}>
                                This Carbon Credit Certificate was issued and is guaranteed by Lune
                                Climate Ltd. for {clientName}.
                            </Typography>
                        </Box>
                    </Box>
                </>
            )}
        </Box>
    )

    return disableModal ? (
        content
    ) : (
        <Modal
            withoutSpacing={true}
            maxWidth={'xs'}
            open={openModal}
            onClose={onClose}
            sx={{
                '.MuiPaper-root': {
                    borderRadius: '32px',
                    overflow: 'hidden',
                    zIndex: 9,
                    '> .MuiBox-root': {
                        height: '100%',
                        display: 'block',
                        overflow: 'hidden',
                        overflowY: 'auto',
                    },
                    ...(isMobile ? mobileSx : ''),
                },
            }}
        >
            {content}
        </Modal>
    )
}

export default CertificateModal
