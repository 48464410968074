import React from 'react'

import PoweredByLuneImg from '../images/PoweredByLune.png'
import { staticImagePath } from '../utils'

const WIDTH = 166
const HEIGHT = 25

// nextjs returns a { src: string, blurDataURL: string, height: number, width: number } when importing an image
// therefore handle native and nextjs case

const PoweredByLune = () => {
    return (
        <a href="https://lune.co" target="_blank" rel="noreferrer">
            <img alt="Powered By Lune" src={staticImagePath(PoweredByLuneImg)} width={WIDTH} />
        </a>
    )
}

type Next12Image = React.ComponentType<{
    alt?: string
    src: any
    width?: number
    height?: number
    layout?: any
}>
type Next14Image = React.ComponentType<{
    alt: string
    src: any
    width?: number | `${number}`
    height?: number | `${number}`
    layout?: any
}>
type NextImage = Next12Image | Next14Image

const PoweredByLuneNextImg = ({ Image }: { Image?: NextImage }) => {
    if (Image) {
        return <Image alt="Powered By Lune" src={PoweredByLuneImg} width={WIDTH} height={HEIGHT} />
    }
    return (
        <img
            alt="Powered By Lune"
            src={staticImagePath(PoweredByLuneImg)}
            width={WIDTH}
            height={HEIGHT}
        />
    )
}

export const PoweredByLuneNext = (props: { Image?: NextImage }) => {
    return (
        <a href="https://lune.co" target="_blank" rel="noreferrer">
            <PoweredByLuneNextImg {...props} />
        </a>
    )
}

export default PoweredByLune
