import Typography from '@mui/material/Typography'
import { styled } from '@mui/system'

import LoadingWrapper from '../components/LoadingWrapper'
import { LuneTheme } from '../theme'

import Button from './Button'
import Tile from './Tile'

const ImageUpload = ({
    imgSrc,
    title,
    isUploading,
    onButtonClick,
}: {
    imgSrc?: string
    title?: string
    isUploading: boolean
    onButtonClick: () => void
}) => {
    const Image = styled('img')(
        LuneTheme.unstable_sx({
            width: `48px`,
            height: `48px`,
            objectFit: 'cover',
        }),
    )

    const Placeholder = styled('div')(
        LuneTheme.unstable_sx({
            width: `48px`,
            height: `48px`,
            backgroundColor: 'Grey100',
            borderRadius: '50px',
        }),
    )

    return (
        <Tile
            icon={
                <LoadingWrapper sx={{ height: `48px`, width: `48px` }} loading={isUploading}>
                    {imgSrc ? <Image src={imgSrc} alt="Uploaded Image" /> : <Placeholder />}
                </LoadingWrapper>
            }
            rightSide={
                <Button onClick={onButtonClick} disabled={isUploading} variant="text">
                    Upload image
                </Button>
            }
        >
            <div>
                <Typography variant="body1">{title || `Company logo`}</Typography>
                <Typography variant="body3" color={'Grey600'}>
                    JPEG or PNG, 500 by 500 px, up to 200kb.
                </Typography>
            </div>
        </Tile>
    )
}

export default ImageUpload
