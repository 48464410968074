import type { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import { styled, useTheme } from '@mui/material/styles'

const GreyGradient = ({
    children,
    sx,
}: {
    children: React.ReactNode
    sx?: SxProps
}): JSX.Element => {
    const theme = useTheme()
    const styles = {
        background: `linear-gradient(180deg, ${theme.palette.White} 0%, ${theme.palette.Grey100} 100%)`,
    }

    const StyledBox = styled(Box)(styles)
    return <StyledBox sx={sx}>{children}</StyledBox>
}

export default GreyGradient
