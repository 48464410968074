import type { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import * as React from 'react'

export interface ApiReferenceSectionProps {
    children: React.ReactNode[]
    sx?: SxProps
}

const ApiReferenceSection = ({ children, sx, ...rest }: ApiReferenceSectionProps): JSX.Element => {
    if (children.length !== 2) {
        throw new Error(`ApiReferenceSection accepts two children only, given ${children.length}`)
    }

    return (
        <Box
            sx={{
                maxHeight: '100%',
                overflow: 'auto',
                ...sx,
            }}
        >
            <Grid
                container
                spacing={{
                    xs: 4,
                    sm: 4,
                    md: 10,
                    lg: 10,
                    xl: 10,
                }}
                sx={{}}
                {...rest}
            >
                <Grid item sx={{}} xs={12} sm={12} md={6} lg={6} xl={6}>
                    {children[0]}
                </Grid>
                <Grid item sx={{}} xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Box
                        sx={{
                            position: 'sticky',
                            top: '0',
                        }}
                    >
                        {children[1]}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ApiReferenceSection
