import type { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import React, { FC } from 'react'

import { LuneTheme } from '../theme'

const JsonObjectTable: FC<{
    title?: string
    description?: string
    children: React.ReactNode
    sx?: SxProps
}> = ({ title, description, children, sx, ...rest }) => {
    const { spacing, palette, typography } = LuneTheme

    return (
        <Box sx={sx} {...rest}>
            <Typography
                variant="h5"
                color="Grey900"
                sx={{
                    ...typography.h5,
                    color: palette.Grey900,
                }}
            >
                {title ?? `Attributes`}
            </Typography>
            {description && (
                <Typography
                    variant="body3"
                    component="p"
                    color="Grey900"
                    sx={{
                        mt: spacing(3),
                        ...typography.body3,
                        color: palette.Grey900,
                    }}
                >
                    {description}
                </Typography>
            )}
            <Stack
                sx={{ mt: spacing(5) }}
                spacing={3}
                divider={
                    <Divider
                        sx={{ borderColor: palette.Grey300, color: palette.Grey300 }}
                        orientation="horizontal"
                        flexItem
                    />
                }
            >
                {children}
            </Stack>
        </Box>
    )
}

export default JsonObjectTable
