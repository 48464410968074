import { FC, useMemo } from 'react'

enum GradientType {
    RADIAL = 'radial',
    LINEAR = 'linear',
}

enum Octaves {
    SIMPLE = 1,
    COMPLEX = 2,
}

const colorPairs = [
    ['#FFEF3D', '#0F7FFF'],
    ['#94FFFF', '#0F7FFF'],
    ['#FFEF3D', '#1EC381'],
    ['#50F7F1', '#B1F67B'],
    ['#B1F67B', '#0F7FFF'],
    ['#00DAFF', '#FCED76'],
    ['#FFFFFF', '#FFEF3D'],
    ['#FFFFFF', '#1EC381'],
    ['#FFFFFF', '#0F7FFF'],
    ['#0F7FFF', '#FFEF3D'],
]

// eslint-disable-next-line complexity
const getFrequency = (
    type: GradientType,
    formattedHash: string,
    octaves: Octaves,
): [number, number] => {
    // RADIAL => 0.001 - 0.003
    // LINEAR + SIMPLE => 0.002 - 0.004
    // LINEAR + COMPLEX => 0.002 - 0.003
    const fX = Number(formattedHash[1]) === 0 ? 1 : Number(formattedHash[1])
    const fY = Number(formattedHash[2]) === 0 ? 1 : Number(formattedHash[2])
    let frequencyX
    let frequencyY

    if (type === GradientType.RADIAL) {
        frequencyX = Math.round(fX / 3) === 1 ? 0.003 : Math.round(fX / 3) === 2 ? 0.004 : 0.005
        frequencyY = Math.round(fY / 3) === 1 ? 0.002 : Math.round(fY / 3) === 2 ? 0.003 : 0.004
    } else {
        if (octaves === Octaves.SIMPLE) {
            frequencyX = Math.round(fX / 3) === 1 ? 0.002 : Math.round(fX / 3) === 2 ? 0.003 : 0.004
            frequencyY = Math.round(fY / 3) === 1 ? 0.002 : Math.round(fY / 3) === 2 ? 0.003 : 0.004
        } else {
            frequencyX = fX % 2 === 0 ? 0.002 : 0.003
            frequencyY = fY % 2 === 0 ? 0.002 : 0.003
        }
    }
    return [frequencyX, frequencyY]
}

/**
 * GradientSVG component.
 *
 * This component generates an SVG with either a radial or linear gradient based on the given hashNumber prop.
 * The gradient style is determined by the characteristics of the hashNumber in the following manner:
 *
 * Color pick is determined by [0]
 * Frequency is determined by [1] and [2]
 * Rotate is determined by [3]
 * Octaves is determined by [4]
 * The gradient type (either 'radial' or 'linear') is determined by [5]
 * The seed for fractalNoise is determined by [6] + [9] * 110
 *
 * @param {object} props - Component props.
 * @param {number} props.hashNumber - A number used to generate the gradient SVG.
 *
 * @example
 * return <GradientSVG hashNumber={1234567890} />;
 *
 * @returns {React.Element} The GradientSVG component.
 */
const GradientSVG: FC<{ hashNumber: number }> = ({ hashNumber }) => {
    const formattedHash = String(hashNumber).padEnd(10, '0')
    const type: GradientType =
        Number(formattedHash[5]) % 2 === 0 ? GradientType.RADIAL : GradientType.LINEAR
    const seed: number = Number(formattedHash[6] + formattedHash[9]) * 110
    const rotate: number = Number(formattedHash[3]) * 36
    const deviation: number[] = type === GradientType.RADIAL ? [20, 20] : [0, 0]
    const octaves: Octaves =
        type === GradientType.RADIAL
            ? Octaves.SIMPLE
            : Number(formattedHash[4]) % 2 === 0
              ? Octaves.SIMPLE
              : Octaves.COMPLEX

    const colorPair: string[] = useMemo(() => {
        return type === GradientType.LINEAR && octaves === Octaves.SIMPLE
            ? [colorPairs[parseInt(formattedHash[0])][1], colorPairs[parseInt(formattedHash[0])][0]]
            : colorPairs[parseInt(formattedHash[0])]
    }, [type, octaves])

    const frequency: number[] = useMemo(
        () => getFrequency(type, formattedHash, octaves),
        [type, formattedHash, octaves],
    )

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 700 700"
            width="700"
            height="700"
            opacity="0.72"
        >
            <defs>
                {type === GradientType.RADIAL ? (
                    <radialGradient id={`ffflux-gradient-${hashNumber}`}>
                        <stop offset="0%" stopColor={colorPair[0]}></stop>
                        <stop offset="100%" stopColor={colorPair[1]}></stop>
                    </radialGradient>
                ) : (
                    <linearGradient
                        id={`ffflux-gradient-${hashNumber}`}
                        gradientTransform={`rotate(${rotate}, 0.5, 0.5)`}
                        x1="50%"
                        y1="0%"
                        x2="50%"
                        y2="100%"
                    >
                        <stop stopColor={colorPair[0]} stopOpacity="1" offset="0%"></stop>
                        <stop stopColor={colorPair[1]} stopOpacity="1" offset="100%"></stop>
                    </linearGradient>
                )}
                <filter
                    id={`ffflux-filter-${hashNumber}`}
                    x="-20%"
                    y="-20%"
                    width="140%"
                    height="140%"
                    filterUnits="objectBoundingBox"
                    primitiveUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feTurbulence
                        baseFrequency={`${frequency[0]} ${frequency[1]}`}
                        numOctaves={octaves}
                        seed={seed}
                        type="fractalNoise"
                        stitchTiles="stitch"
                        x="0%"
                        y="0%"
                        width="100%"
                        height="100%"
                        result="turbulence"
                    ></feTurbulence>
                    <feGaussianBlur
                        stdDeviation={`${deviation[0]} ${deviation[1]}`}
                        x="0%"
                        y="0%"
                        width="100%"
                        height="100%"
                        in="turbulence"
                        edgeMode="duplicate"
                        result="blur"
                    ></feGaussianBlur>
                    <feBlend
                        mode="color-dodge"
                        x="0%"
                        y="0%"
                        width="100%"
                        height="100%"
                        in="SourceGraphic"
                        in2="blur"
                        result="blend"
                    ></feBlend>
                </filter>
            </defs>
            {hashNumber && (
                <rect
                    width="700"
                    height="700"
                    fill={`url(#ffflux-gradient-${hashNumber})`}
                    filter={`url(#ffflux-filter-${hashNumber})`}
                ></rect>
            )}
        </svg>
    )
}

export default GradientSVG
