import type { SxProps } from '@mui/material'
import MUIContainer from '@mui/material/Container'
import { styled } from '@mui/material/styles'
import { Breakpoint } from '@mui/system'
import * as React from 'react'
import { FC } from 'react'

export interface ContainerProps {
    sx?: SxProps
    maxWidth?: Breakpoint | false
    children: React.ReactNode
}

const Container: FC<ContainerProps> = ({ children, sx, maxWidth = 'xl' }) => {
    const StyledContainer = styled(MUIContainer)({
        paddingLeft: '0px',
        paddingRight: '0px',
    })
    return (
        <StyledContainer maxWidth={maxWidth} sx={sx}>
            {children}
        </StyledContainer>
    )
}

export default Container
