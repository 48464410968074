import type { SxProps } from '@mui/material'
import Container from '@mui/material/Container'
import * as React from 'react'

const XlContainer = ({ children, sx }: { children: React.ReactNode; sx?: SxProps }) => {
    return (
        <Container maxWidth="xl" sx={{ paddingLeft: '0px', paddingRight: '0px', ...sx }}>
            {children}
        </Container>
    )
}

export default XlContainer
